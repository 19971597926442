<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.scss";
import { mapActions, mapState } from "vuex";

export default {
  name: "MainSlider",
  components: { Swiper, SwiperSlide },

  props: {
    name: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      images: [],
      optionsSlider: {
        speed: 800,
        spaceBetween: 16,
        loop: true,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".main-slider__btn-next",
          prevEl: ".main-slider__btn-prev",
        },
        pagination: {
          el: ".swiper-custom-pagination",
          clickable: true,
          renderBullet: (_, className) => {
            return `
              <div class='swiper-custom-pagination__button ${className}'>
                <div class='swiper-custom-pagination__line-box'>
                  <div class='swiper-custom-pagination__line'></div>
                  <div class='swiper-custom-pagination__line-animate'></div>
                </div>
              </div>
            `;
          },
        },
      },
    };
  },

  watch: {},

  computed: {
    ...mapState({
      innerWidth: (state) => state.innerWidth,
    }),

    useImage() {
      return this.innerWidth > 576 ? "thumbnail_url" : "thumbnail_mobile_url";
    },
  },

  methods: {
    ...mapActions({
      getSlider: "slider/getSlider",
    }),
  },

  async mounted() {
    const slider = await this.getSlider(this.name);

    if (slider) {
      this.images = [...slider.slides];
    }
  },
};
</script>

<template>
  <div class="main-slider">
    <div class="main-slider__container">
      <Swiper class="swiper" :options="optionsSlider">
        <SwiperSlide
          v-for="(item, index) in images"
          :key="index"
          class="main-slider__swiper-slide"
        >
          <a
            :href="item.url ?? '#'"
            :class="{ 'main-slider__disabled-link': !item.url }"
            target="_blank"
          >
            <img :src="item[useImage]" />
          </a>
        </SwiperSlide>
      </Swiper>

      <button class="main-slider__btn main-slider__btn-prev">
        <span class="icon-slider-left" />
      </button>
      <button class="main-slider__btn main-slider__btn-next">
        <span class="icon-slider-right" />
      </button>
    </div>

    <div
      class="swiper-custom-pagination swiper-custom-pagination_autoplay"
    ></div>
  </div>
</template>

<style lang="scss">
.main-slider {
  max-width: 940px;
  width: 100%;
  padding: 0 48px;

  @media (max-width: 1359px) {
    max-width: 100vw;
  }

  @media (max-width: 768px) {
    padding: 0 32px;
  }

  &__disabled-link {
    pointer-events: none;
  }

  &__swiper-slide {
    max-height: 360px;
    overflow: hidden;
    border-radius: 16px;
    display: flex;

    > a {
      display: flex;

      > img {
        object-fit: cover;
        max-height: 360px;
        width: 100%;
      }
    }
  }

  &__btn {
    padding: 14px;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-white;
    border: 0;
    box-shadow: 0px 0px 16px 0px rgba(33, 34, 36, 0.08);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;

    @media (max-width: 1023px) {
      width: 36px;
      height: 36px;
      padding: 10px;
    }

    @media (max-width: 899px) {
      display: none;
    }

    > span {
      background: #1e1f21;
      width: 20px;
      height: 20px;

      @media (max-width: 1023px) {
        width: 16px;
        height: 16px;
      }
    }

    &-prev {
      left: -24px;

      @media (max-width: 1023px) {
        left: -16px;
      }
    }

    &-next {
      right: -24px;

      @media (max-width: 1023px) {
        right: -16px;
      }
    }
  }

  &__container {
    position: relative;
  }
}

.swiper-custom-pagination {
  display: flex;
  height: 20px;
  width: calc(100% - 48px);
  margin: 16px auto 0 auto;
  padding: 0 4px;
  justify-content: center;

  @media (max-width: 1023px) {
    margin: 8px auto 0 auto;
    height: 12px;
  }

  &__button {
    max-width: 120px;
    width: 100%;
    height: 100%;
    padding: 0 4px;
    cursor: pointer;
    transition: opacity ease-in 0.2s;
  }

  &__line-box {
    width: 100%;
    height: 100%;
    position: relative;

    > div {
      position: absolute;
      //width: calc(100% - 8px);
      width: 100%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 4px;
    }

    > .swiper-custom-pagination__line {
      //width: 100%;
      background: rgb(30, 31, 33);
      opacity: 0.1;
      border-radius: 4px;
      transition: opacity 0.4s ease-in-out;
    }

    &:hover {
      > .swiper-custom-pagination__line {
        opacity: 0.25;
      }
    }

    &:active {
      > .swiper-custom-pagination__line {
        opacity: 0.4;
      }
    }
  }

  .swiper-pagination-bullet-active {
    .swiper-custom-pagination__line-animate {
      border-radius: 4px;
      background: rgb(30, 31, 33);
      transition: background 0.5s;
    }
  }

  &_autoplay {
    .swiper-pagination-bullet-active {
      .swiper-custom-pagination__line-animate {
        animation: timeToNextSlide 10000ms ease-in-out forwards;
      }
    }
  }
}

@keyframes timeToNextSlide {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
